import React from "react"

const LogoBlocks = props => {
    const blocks = [0,1,2,3,4,5,6];
    return (
        <span className={`logo-blocks ${props.className||''}`}>
          {blocks.map(i => {
              return <span key={i} data-i={i} className={`logo-block ${props.subClassName||''}`} />
          })}
        </span>
    )
}

export default LogoBlocks;