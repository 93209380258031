exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agence-js": () => import("./../../../src/pages/agence.js" /* webpackChunkName: "component---src-pages-agence-js" */),
  "component---src-pages-construction-js": () => import("./../../../src/pages/construction.js" /* webpackChunkName: "component---src-pages-construction-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentions-legales-js": () => import("./../../../src/pages/mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */),
  "component---src-pages-renovation-js": () => import("./../../../src/pages/renovation.js" /* webpackChunkName: "component---src-pages-renovation-js" */),
  "component---src-pages-tous-les-projets-js": () => import("./../../../src/pages/tous-les-projets.js" /* webpackChunkName: "component---src-pages-tous-les-projets-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

