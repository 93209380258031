import React from "react"
import {Link} from "gatsby"
import PropTypes from 'prop-types'

const NavLink = (props) => (
    <Link className={'nav-link'} {...props}>
      <span className={"logo-text"}>{props.children}</span>
      <span className={'nav-block'} />
    </Link>
)

NavLink.propTypes = {
    'to': PropTypes.string.isRequired
}

export default NavLink;