import React, {useRef} from "react"
import {
    TransitionGroup,
    Transition as ReactTransition,
} from "react-transition-group"

const timeout = 250
const getTransitionStyles = {
    entering: {
        position: `absolute`,
        opacity: 0,
    },
    entered: {
        transition: `opacity ${timeout}ms ease-in-out`,
        opacity: 1,
    },
    exiting: {
        transition: `opacity ${timeout}ms ease-in-out`,
        opacity: 0,
    },
}

const Transition = ({ children, location }) => {
    const nodeRef = useRef(null)
    return (
        <TransitionGroup>
            <ReactTransition
                key={location.pathname}
                nodeRef={nodeRef}
                timeout={{
                    enter: timeout,
                    exit: timeout,
                }}
            >
                {status => (
                    <div
                        ref={nodeRef}
                        style={{
                            ...getTransitionStyles[status],
                        }}
                    >
                        {children}
                    </div>
                )}
            </ReactTransition>
        </TransitionGroup>
    )
}

export default Transition