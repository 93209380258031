import React from "react"
import LogoBlocks from "./logo-blocks"

const Logo = (props) => {
    const {className=''} = props;
    return (
        <span className={`logo ${className||''}`}>
            <span className={"logo__text logo-text"}>
                <span className="fl">P</span>
                <span className="fl">R</span>
                <span className={"strong architectes"}>
                    <span className="fl">A</span>
                </span>
                <span className={"strong"}>
                    <span className="fl">A</span>
                </span>
            </span>{' '}
            <LogoBlocks />
            <span className={"logo__subtitle"}><em>Philippe Roux</em> <strong>Architectes Associés</strong> </span>
        </span>
    )
}

export default Logo