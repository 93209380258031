import NavLink from "./nav-link"
import React, {useEffect} from "react"
import {Link} from "gatsby"
import Logo from "./logo";

let scrollBackup = NaN;

const Header = ({location, siteTitle=''}) => {

    const clickToggleNav = (event) => {
        toggleNav();
    }
    const toggleNav = (force) => {
        const htmlClassList = window.document.documentElement.classList,
            initialDisplayMenu = htmlClassList.contains('mobile-nav-toggle');
        let displayMenu = !initialDisplayMenu;
        if(force === true || force === false){
            displayMenu = force;
        }
        if(displayMenu){
            scrollBackup = window.scrollY;
        }
        htmlClassList.toggle('mobile-nav-toggle',displayMenu);
        if(displayMenu !== initialDisplayMenu) {
            if (displayMenu) {
                window.document.body.scrollTop = scrollBackup;
            } else {
                window.scroll(0, scrollBackup);
                scrollBackup = NaN;
            }
        }

    }
    const {pathname} = {...location};
    const theme = pathname === '/' ? 'introduction' : 'default';

    useEffect(() => {
        toggleNav(false);
    },[]);


    return <header className={`header header--${theme}`}>
        <div className={'container'}>
            <h1 style={{ margin: 0 }}>
                <Link to={"/tous-les-projets"}>
                    <Logo className={"logo--header"} />
                </Link>
            </h1>
            <button onClick={clickToggleNav} className={'toggle-nav'} type={'button'} aria-label={"Menu"}><span>Menu</span> </button>
            <nav>
                <NavLink id="nav-home" to={"/tous-les-projets"}>TOUS LES PROJETS </NavLink>
                <NavLink id="nav-construction" to={"/construction"}>CONSTRUCTION </NavLink>
                <NavLink id="nav-renovation" to={"/renovation"}>RÉNOVATION </NavLink>
                <NavLink id="nav-agence" to={"/agence"}>AGENCE </NavLink>
                <button type="button" className={'placeholder'} onClick={clickToggleNav} aria-label={"Menu mobile"}> </button>
            </nav>
        </div>
    </header>
}
export default Header
