import React, {useEffect, useTransition} from "react";
import {graphql, Link, useStaticQuery} from "gatsby";
import "normalize.css"
import "../styles/main.scss"
import Header from "../components/header";
import Transition from "../components/transition";

const PageLayout = ({pageProps, pageEl}) => {
    const [year, setYear] = React.useState(new Date().getFullYear());
    const [, startTransition] = useTransition()
    useEffect(() => {
        startTransition(() => {
            setYear(new Date().getFullYear());
        });
    }, []);
    const {location} = pageProps
    const defaultAuthor = "Philippe Roux Architectes Associés";
    const {
        site: {
            siteMetadata: {
                title = defaultAuthor,
                author = defaultAuthor
            }
        }
    } = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                    author
                }
            }
        }
    `);
    return (
        <div id="site" className="site">
            <Header location={location} siteTitle={title}/>
            <main><Transition key='transition' location={location}>{pageEl}</Transition></main>
            <footer>
                <div className="col">
                    <span aria-hidden={"true"}>© {year}, {author}</span>
                </div>
                <div className="col col--right">
                    <Link className={`terms`} to={`/mentions-legales`}>Mentions Légales</Link>
                </div>
            </footer>
        </div>
    );
}

export default PageLayout
