/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// exports.onRouteUpdate = ({ location, prevLocation }) => {
//     window.document.documentElement.classList.remove('mobile-nav-toggle');
// }
//
// const transitionDelay = 250
//
// exports.shouldUpdateScroll = ({ routerProps, getSavedScrollPosition }) => {
//     // // We use a temporary hack here, see #7758
//     window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
//     return false
// }

const {wrapPageElement} = require("./src/layouts/layout")

exports.wrapPageElement = wrapPageElement